export function formatDistanceToM(
  value: number,
  inputMetric: 'm' | 'km' = 'm'
) {
  if (inputMetric === 'km') {
    value = value * 1000;
  }

  return `${value} m`;
}

export function formatDistanceToKm(
  value: number,
  inputMetric: 'km' | 'm' = 'km',
  decimalPlaces: number = 2
) {
  if (inputMetric === 'm') {
    value = value / 1000;
  }

  return `${value.toFixed(decimalPlaces)} km`;
}
